import {Container, Navbar, NavDropdown, Nav, Offcanvas} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../assets/images/mta_logo.png";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";
import { listTrips } from "../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from 'react-tooltip'
import {
  deleteTrip as deleteTripMutation
} from "../graphql/mutations";

export async function fetchTrips(username) {
    try {
        const apiData = await API.graphql({
            query: listTrips,
            variables: {
                filter: {
                    author: { eq: username },
                },
            },
        });
        const tripsFromAPI = apiData.data.listTrips.items;
        return tripsFromAPI;
    } catch (error) {
        console.error("Error fetching user trips:", error);
        throw error; // Rethrow the error to propagate it further
    }
}

const SignButton = styled.a`
    color: white;
    padding: 8px 23px;
    background-color: #37ab31;
    display: inline-block;
    margin-bottom: 0;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;   
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;
    touch-action: manipulation;
    border-radius: 8px;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s;
    text-decoration: none;
    &:hover {
        color:white;
        box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}
`

function Header(props) {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [tripToDeleteId, setTripToDeleteId] = useState(null);
  
    const handleClose = () => setShowConfirm(false);
    const handleShow = () => setShowConfirm(true);
  

    const openDeleteModal = (event, id) => {
        event.preventDefault();
        setTripToDeleteId(id);
        handleShow();
        setShowOffcanvas(false);
    };

    // State to manage the visibility of the Offcanvas
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const openModal = async () => {
        props.openAuthenticatorModal();
        setShowOffcanvas(false);
    };

    
    async function handleDeleteTrip(id) {
        try {
            // Perform the deletion using a GraphQL mutation
            console.log(id);
            await API.graphql({
              query: deleteTripMutation,
              variables: { input: { id } },
            });
    
            // Close the modal and update the trips list
            handleClose();
            const userTrips = await fetchTrips(user.username);
            props.setTrips(userTrips);
        } catch (error) {
            console.error("Error deleting trip:", error);
    
            // Handle specific error types or provide more informative error messages
            if (error.errors) {
                // Handle errors returned by the GraphQL server
                error.errors.forEach((err) => {
                    console.error("GraphQL Error:", err.message);
                });
            } else {
                // Handle other types of errors
                console.error("Unexpected error:", error.message);
            }
        }
    }

    useEffect(() => {
        const fetchUserTrips = async () => {
            if (authStatus === 'authenticated' && user.username) {
                const userTrips = await fetchTrips(user.username);
                props.setTrips(userTrips);
            }
        };
    
        fetchUserTrips();
    }, [authStatus, user]);

    return (
        <>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="https://mytripassistant.com">
                        <img
                            src={logo}
                            width="230px"
                            className="d-inline-block align-top"
                            alt="MyTripAssistant logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar"  onClick={() => setShowOffcanvas(!showOffcanvas)} />
                    <Navbar.Offcanvas id="offcanvasNavbar" placement="end" show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        <Nav className="justify-content-start align-items-center flex-grow-1 pe-md-3 mb-3 mb-md-0">
                            <Nav.Link href="https://mytripassistant.com">Home</Nav.Link>
                            <Nav.Link href="#howitworks">How it works</Nav.Link>
                            <Nav.Link href="#features">Features</Nav.Link>
                        </Nav>
                        <Nav className="justify-content-end align-items-center flex-grow-1 pe-md-3 mb-3 mb-md-0">
                            {authStatus === 'authenticated' ? (
                                <NavDropdown   
                                    title={`Hi ${user && user.attributes && user.attributes.name}`}
                                    id="offcanvasNavbarDropdown"
                                >
                                    <NavDropdown.Item href="#"><b>My Trips</b></NavDropdown.Item>
                                    {props.trips.map((trip, index) => (
                                        <NavDropdown.Item href={`/trip/${trip.itineraryId}`} key={index} className="navbar-trip">
                                            {trip.itineraryName} 
                                            <FontAwesomeIcon 
                                                icon={faTrashAlt}
                                                onClick={(event) => openDeleteModal(event, trip.id)} 
                                                data-tooltip-id="delete-tooltip" data-tooltip-content={"Delete trip"} 
                                            />
                                            <Tooltip id="delete-tooltip" place="right" style={{ borderRadius: "8px", zIndex: "100000" }} />
                                        </NavDropdown.Item>
                                    ))}
                                    {authStatus === 'authenticated' && (
                                        <>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                            ) : (
                                <SignButton
                                    onClick={openModal}
                                >
                                Sign Up / Sign In
                                </SignButton>
                            )}
                        </Nav>
                        <Nav className="justify-content-end align-items-center">
                            <a
                                className="contact-us"
                                href={"mailto:info@mytripassistant.com"}
                            >
                                Contact us
                            </a>
                        </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <Modal 
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirm}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm action</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this trip?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteTrip(tripToDeleteId)}>
                        Yes, delete it <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  );
}

export default Header;